<template>
  <view-item-setting>
    <template #operation>
      <en-button :disabled="!store.accessRightsHash.VEHICLE_INSPECTION_EDIT" type="primary" @click="operation.add.click">添加检测项目</en-button>
    </template>

    <tabs-maintain
      v-model="tabs.active"
      :options="[
        { label: '未启用', value: 'N' },
        { label: '已启用', value: 'Y' }
      ]"
      :config="{ order: 'before' }"
      :method="table.get"
    ></tabs-maintain>

    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          :height="height"
          code="VHISTPNFD"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
        >
          <template #OPERATION="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionItemDto'] }">
            <button-delete :method="table.operation.delete.click" :params="row" v-if="row.enable?.code === 'N'">删除</button-delete>
            <en-button v-if="row.enable?.value" type="primary" link @click="table.operation.enable.click(row)">禁用</en-button>
            <en-button v-if="!row.enable?.value" type="primary" link @click="table.operation.enable.click(row)">启用</en-button>
          </template>

          <template #SERIAL_NO="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionItemDto'] }">
            <en-button type="primary" link @click="table.serialNo.click(row)">{{ row.serialNo }}</en-button>
          </template>

          <template #STATUS="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionItemDto'] }">
            <en-tag :type="row.enable?.code === 'N' ? 'info' : ''">{{ row.enable?.code === 'Y' ? '已启用' : '未启用' }}</en-tag>
          </template>
        </table-dynamic>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑' : '添加' + '检测类别'">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :loading="detail.form.loading" :ref="setRef('detailForm')">
      <en-form-item label="项目编号">
        <en-input :model-value="detail.form.data.serialNo" disabled placeholder="项目编号"></en-input>
      </en-form-item>
      <en-form-item label="检测项目名称" prop="name">
        <en-input v-model="detail.form.data.name" :disabled="detail.form.disabled"></en-input>
      </en-form-item>
      <en-form-item>
        <en-checkbox
          true-label="Y"
          false-label="N"
          :model-value="detail.form.data.locationSensitive?.code"
          :disabled="detail.form.disabled"
          @change="detail.form.locationSensitive.change"
        >
          此项目按左前、右前、左后、右后分别检测
        </en-checkbox>
      </en-form-item>
      <en-form-item label="检测项目类别" prop="category.id">
        <select-maintain
          v-model="detail.form.data.category"
          :ajax="{
            action: 'GET /enocloud/common/vehicle/inspection/category',
            params: (params, value) => (params.payload = { categoryName: value, enableCode: 'Y' })
          }"
          :props="{ label: 'name', value: '' }"
          :disabled="detail.form.disabled"
          value-key="id"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>

      <en-form-item label="检测项目分析">
        <en-form :model="detail.form.data" :rules="detail.form.rules" label-position="left" :ref="setRef('detailItemsForm')" inline>
          <en-form-item label="状态良好(绿色)" inline-message prop="excellentConditionDescription">
            <en-input
              :disabled="detail.form.disabled"
              v-model="detail.form.data.excellentConditionDescription"
              maxlength="5"
              placeholder="最多可输入5个中文字符"
            ></en-input>
          </en-form-item>
          <en-form-item label="密切关注(黄色)" inline-message prop="fairConditionDescription">
            <en-input
              :disabled="detail.form.disabled"
              v-model="detail.form.data.fairConditionDescription"
              maxlength="5"
              placeholder="最多可输入5个中文字符"
            ></en-input>
          </en-form-item>
          <en-form-item label="急需处理(红色)" inline-message prop="poorConditionDescription">
            <en-input
              :disabled="detail.form.disabled"
              v-model="detail.form.data.poorConditionDescription"
              maxlength="5"
              placeholder="最多可输入5个中文字符"
            ></en-input>
          </en-form-item>
        </en-form>
      </en-form-item>

      <en-form-item label="检测项目说明">
        <en-input type="textarea" v-model="detail.form.data.description" :disabled="detail.form.disabled"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click" :disabled="detail.form.disabled">确定</button-ajax>
      <button-ajax :method="detail.footer.enable.click" :disabled="detail.form.disabled">确定并启用</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      tabs: {
        active: ''
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/inspection/item',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { enableCode: this.tabs.active }
            }
          },
          delete: {
            action: 'DELETE /enocloud/common/vehicle/inspection/item/:itemId',
            loading: true
          },
          enable: {
            action: 'POST /enocloud/common/vehicle/inspection/item/enable',
            loading: true,
            convert(data) {
              data.enable.code = data.enable.code === 'Y' ? 'N' : 'Y'
              return data
            }
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudCommonDefinitions['VehicleInspectionItemDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            },
            enable: {
              async click(row: EnocloudCommonDefinitions['VehicleInspectionItemDto']) {
                await this.table.enable({
                  payload: Object.assign({}, row, { enable: { code: row.enable?.value ? 'N' : 'Y' } })
                })
                return this.table.get()
              }
            }
          },
          serialNo: {
            click(row: EnocloudCommonDefinitions['VehicleInspectionItemDto']) {
              this.detail.form.init()
              this.detail.form.data.id = row.id
              this.detail.form.get()
              this.detail.visible = true
            }
          }
        },
        config: {
          NAME: { header: { filter: { type: 'text', field: 'itemName' } } },
          PREPARED_DATETIME: {
            header: {
              filter: { type: 'date', field: ['startDate', 'endDate'], props: { type: 'daterange' } }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            ajax: {
              get: {
                action: 'GET /enocloud/common/vehicle/inspection/item/:itemId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/common/vehicle/inspection/item',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/common/vehicle/inspection/item',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              enable: {
                action: 'POST /enocloud/common/vehicle/inspection/item/enable',
                loading: true,
                params(params) {
                  params.payload = Object.assign({}, this.detail.form.data)
                  params.payload.enable = { code: 'Y', message: '', type: '', description: '' }
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请输入检测类别名称' }],
              'category.id': [{ required: true, message: '请选择检测项目类别' }],
              excellentConditionDescription: [{ required: true, message: '请输入文本' }],
              fairConditionDescription: [{ required: true, message: '请输入文本' }],
              poorConditionDescription: [{ required: true, message: '请输入文本' }]
            },
            computed: {
              disabled() {
                return this.detail.form.data.enable?.value || !this.store.accessRightsHash.VEHICLE_INSPECTION_EDIT
              }
            },
            children: {
              locationSensitive: {
                change(value: 'Y' | 'N') {
                  this.detail.form.data.locationSensitive = { code: value, description: '', message: '', type: '' }
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.refs.detailItemsForm.validate()
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            },
            enable: {
              async click() {
                await this.refs.detailItemsForm.validate()
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                await this.detail.form.enable()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
